<template>
  <v-row class="fill-height">
    <v-col>
      <v-row>
        <v-col md="12">
          <v-card>
            <v-card-title>
              <v-icon class="mr-2">mdi-account-hard-hat</v-icon>
              Lista de funcionários
              <v-spacer></v-spacer>
              <v-text-field
                class="mr-3"
                append-icon="mdi-magnify"
                label="Busca"
                single-line
                hide-details
              />
              <v-spacer></v-spacer>
              <v-btn color="success" :to="{ name: 'employeesCreate' }">
                <v-icon left>mdi-plus-thick</v-icon>
                Novo funcionário
              </v-btn>
            </v-card-title>
          </v-card>
        </v-col>
        <v-col md="12">
          <v-row justify="center">
            <v-col md="12" lg="6" sm="12">
              <v-data-table
                v-model="selected"
                :headers="headers"
                :items="employees"
                item-key="id"
                class="elevation-1"
                v-show="employees"
              >
                <template v-slot:item.actions="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        v-bind="attrs"
                        v-on="on"
                        :to="{
                          name: 'employee',
                          params: { id: item.id }
                        }"
                      >
                        <v-icon>mdi-eye</v-icon>
                      </v-btn>
                    </template>
                    <span>Visualizar</span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { EMPLOYEES_GET } from "@/store/actions/employees.type";
import { EMPLOYEES_RESET } from "@/store/mutations/employees.type";
import store from "@/store";
import { mask } from "vue-the-mask";
import { mapGetters } from "vuex";

export default {
  name: "Employees",

  directives: { mask },

  async beforeRouteUpdate(to, from, next) {
    // Reset state if user goes from /editor/:id to /editor
    // The component is not recreated so we use to hook to reset the state.
    await store.commit(EMPLOYEES_RESET);
    return next();
  },
  async beforeRouteEnter(to, from, next) {
    Promise.all([
      store.commit(EMPLOYEES_RESET),
      store.dispatch(EMPLOYEES_GET)
    ]).then(() => {
      next();
    });
  },

  data: () => ({
    pagination: {
      sortBy: "name"
    },
    search: "",
    selected: [],
    headers: [
      {
        text: "Nome",
        align: "center",
        value: "name"
      },
      { text: "CPF", value: "cpf", align: "center" },
      { text: "Categoria", value: "categories", align: "center" },
      { text: "Editar", value: "actions", align: "center", sortable: false }
    ]
  }),

  computed: {
    ...mapGetters(["employees", "checkUsers"])
  }
};
</script>
